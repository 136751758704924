export const usePopoverHover = () => {
  const popoverHover = ref(false)
  const popoverTimeout = ref()

  const hoverPopover = (e, open): void => {
    popoverHover.value = true
    if (!open) {
      e.target.parentNode.click()
    }
  }

  const closePopover = (close) => {
    popoverHover.value = false
    if (popoverTimeout.value) clearTimeout(popoverTimeout.value)
    popoverTimeout.value = setTimeout(() => {
      if (!popoverHover.value) {
        close()
      }
    }, 100)
  }

  const clickPopover = (e, open) => {
    // prevent strange behavior when a user hover and click right away
    if (open) {
      e.preventDefault()
    }
  }

  return { hoverPopover, closePopover, clickPopover, popoverHover }
}
